// TODO fill this in

import type { DragnetBaseOptions } from '@zg-rentals/dragnet-base';
import { Dragnet } from '@zg-rentals/dragnet-base';
import type { JsExceptionRecordDto, PublishingResponseDto } from '@zg-rentals/ts-dragnet-client';
import { createClient, DragnetJsBrowserCollectionHandler_browserExceptions } from '@zg-rentals/ts-dragnet-client';
import { getEnvironment, injectAppNameHeader, isDev } from '@zg-rentals/environment-utils';
import { getGlobalLogger } from '@zg-rentals/logger-browser';

type DragnetBrowserOptions = Omit<DragnetBaseOptions, 'request' | 'throttleTime'>;

export const DEFAULT_API = 'https://dragnet.hotpads.com';

async function post(batch: Array<JsExceptionRecordDto>, options: { appName: string }): Promise<PublishingResponseDto> {
  if (isDev()) {
    return { success: true, message: 'skipped due to dev mode' };
  }

  const logger = getGlobalLogger('dragnetBrowser');
  try {
    const { appName } = options;

    const client = createClient({
      prefixUrl: DEFAULT_API,
      headers: {
        ...injectAppNameHeader({}, appName),
      },
    });

    const response = await DragnetJsBrowserCollectionHandler_browserExceptions(
      {
        environment: getEnvironment() ?? 'production',
        records: batch,
      },
      client,
    );

    return response;
  } catch (err) {
    logger?.error(
      {
        errMessage: err.message,
        errStack: err.stack,
      },
      'error making post request',
    );
  }
  return { success: false, message: 'error making post request' };
}

export class DragnetBrowser extends Dragnet {
  constructor(options: DragnetBrowserOptions) {
    super({ ...options, throttleTime: 50, request: post });
  }
}
