import type { Logger } from '@zg-rentals/logger-base';
import type { MonitorPlugin } from './plugin';
import type { MonitorReporter } from './reporter';
import type { StatController } from './statController';

export interface MonitorInterface<PluginType, ReporterType> {
  logger?: Logger;
  plugins?: Array<PluginType>;
  reporters?: Array<ReporterType>;
  ignoreStart?: boolean;
}

export interface MetricOptions {
  tags?: Record<string, unknown>;
  [key: string]: unknown;
}

export interface CountOptions {
  name: string;
  amount?: number;
  options?: MetricOptions;
}

export interface MeasureOptions {
  name: string;
  amount: number;
  options?: MetricOptions;
}
export interface Monitor {
  count: (opts: CountOptions) => Promise<void>;
  measurementWithGauges: (opts: MeasureOptions) => Promise<void>;
  gauges: (opts: MeasureOptions) => Promise<void>;
  error: ({ error }: { error: Error }) => Promise<void>;
}

export class BaseMonitor<PluginType extends MonitorPlugin, ReporterType extends MonitorReporter> implements Monitor {
  public readonly reporters: Array<ReporterType> = [];
  public readonly stats: Record<string, StatController> = {};

  constructor({ logger, plugins = [], reporters = [], ignoreStart }: MonitorInterface<PluginType, ReporterType> = {}) {
    this.reporters = reporters;

    this.reporters.forEach((reporter) => {
      const stats = reporter.onInitialize(logger);
      if (stats) {
        this.stats[reporter.reporterName] = stats;
      }
    });

    if (!ignoreStart) {
      this.count({ name: 'Monitor started' });
    }

    plugins.forEach((plugin) => {
      const stats = plugin.onInitialize(this, logger);
      if (stats) {
        this.stats[plugin.pluginName] = stats;
      }
    });
  }

  async count(...counts: Array<CountOptions>) {
    await Promise.all(
      counts.flatMap(({ name, amount = 1, options }) => {
        this.reporters.map((reporter) =>
          reporter.count({
            name,
            amount,
            options,
          }),
        );
      }),
    );
  }

  async gauges(...measures: Array<MeasureOptions>) {
    await Promise.all(
      measures.flatMap(({ name, amount, options }) => {
        this.reporters.forEach((reporter) =>
          reporter.gauges({
            name,
            amount,
            options,
          }),
        );
      }),
    );
  }

  async measurementWithGauges(...measures: Array<MeasureOptions>) {
    await Promise.all(
      measures.flatMap(({ name, amount, options }) => {
        this.reporters.map((reporter) =>
          reporter.measurementWithGauges({
            name,
            amount,
            options,
          }),
        );
      }),
    );
  }

  async error({ error, ...rest }: { error: Error } & Record<string, unknown>) {
    await Promise.all(this.reporters.map((reporter) => reporter.onError({ error, ...rest })));
  }
}
