// Auto generated file, do not manually modify!
// com.hotpads.dragnet.client.DragnetJsExceptionCategory

export enum DragnetJsExceptionCategory {
  BROWSER_ERROR = 'BROWSER_ERROR',
  NODE_HTTP_REQUEST = 'NODE_HTTP_REQUEST',
  NODE_ERROR = 'NODE_ERROR',
}

export const DragnetJsExceptionCategory_values = [
  DragnetJsExceptionCategory.BROWSER_ERROR,
  DragnetJsExceptionCategory.NODE_HTTP_REQUEST,
  DragnetJsExceptionCategory.NODE_ERROR,
] as const;
